export const useFormatNumber = () => {
  const formatNumber = (num: number | string) => {
    if (typeof num === 'string') {
      num = Number(num)
    }

    if (Number.isNaN(num)) {
      return '0'
    }

    return num.toLocaleString('en-US', {
      minimumFractionDigits: 0,
      maximumFractionDigits: 2
    })
  }

  return { formatNumber }
}
